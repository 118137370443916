/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three"
import React, { useRef } from "react"
import { useGLTF } from "@react-three/drei"
import { GLTF } from "three-stdlib"

type GLTFResult = GLTF & {
  nodes: {
    rail006_1: THREE.Mesh
    rail006_2: THREE.Mesh
    rail006_3: THREE.Mesh
    rail006_4: THREE.Mesh
    rail006_5: THREE.Mesh
    rail006_6: THREE.Mesh
  }
  materials: {
    ["palette.007"]: THREE.MeshStandardMaterial
    ["palette.002"]: THREE.MeshStandardMaterial
    ["palette.003"]: THREE.MeshStandardMaterial
    ["palette.004"]: THREE.MeshStandardMaterial
    ["palette.005"]: THREE.MeshStandardMaterial
    ["palette.006"]: THREE.MeshStandardMaterial
  }
}

export default function Rail({ ...props }: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF("/models/rail.glb") as GLTFResult
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={2}>
        <mesh
          geometry={nodes.rail006_1.geometry}
          material={materials["palette.007"]}
        />
        <mesh
          geometry={nodes.rail006_2.geometry}
          material={materials["palette.002"]}
        />
        <mesh
          geometry={nodes.rail006_3.geometry}
          material={materials["palette.003"]}
        />
        <mesh
          geometry={nodes.rail006_4.geometry}
          material={materials["palette.004"]}
        />
        <mesh
          geometry={nodes.rail006_5.geometry}
          material={materials["palette.005"]}
        />
        <mesh
          geometry={nodes.rail006_6.geometry}
          material={materials["palette.006"]}
        />
      </group>
    </group>
  )
}

useGLTF.preload("/models/rail.glb")
