/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three"
import React, { useRef } from "react"
import { useGLTF } from "@react-three/drei"
import { GLTF } from "three-stdlib"

type GLTFResult = GLTF & {
  nodes: {
    Cylinder: THREE.Mesh
    Cylinder001: THREE.Mesh
    Cone: THREE.Mesh
  }
  materials: {
    ["Material.001"]: THREE.MeshStandardMaterial
    ["Material.002"]: THREE.MeshStandardMaterial
  }
}

export default function Sign({ ...props }: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF("/models/sign.glb") as GLTFResult
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes.Cylinder.geometry}
        material={materials["Material.001"]}
      />
      <mesh
        geometry={nodes.Cylinder001.geometry}
        material={nodes.Cylinder001.material}
        position={[0, 1.97, 0]}
        scale={[-0.17, 1.52, -0.17]}
      />
      <mesh
        geometry={nodes.Cone.geometry}
        material={materials["Material.002"]}
        position={[0, 3.93, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={1.04}
      />
    </group>
  )
}

useGLTF.preload("/models/sign.glb")
