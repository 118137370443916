import { Canvas, useFrame, useThree } from "@react-three/fiber"
import React, { useState, useRef, useMemo, useEffect, useContext } from "react"
import * as THREE from "three"

import Layout from "../components/Layout"

import Cockandballs from "../../static/models/Cockandballs"
import {
  Debug,
  Physics,
  useBox,
  usePlane,
  useSphere,
} from "@react-three/cannon"
import * as CANNON from "@react-three/cannon"
import { ThemeContext } from "../components/ThemeProvider"
import { swapTheme, Themes } from "../utils/themes"
import Lantern from "../../static/models/Lantern"
import Ground from "../../static/models/Ground"
import Sign from "../../static/models/Sign"
import Rail from "../../static/models/Rail"

import * as s from "../styles/home.module.scss"
import { Link } from "gatsby"
import { OrbitControls } from "@react-three/drei"

const Sun = ({ day, updateTheme }) => {
  // let day = theme === Themes.LIGHT

  return (
    <mesh position={[-120, 20, -80]} onClick={() => swapTheme(updateTheme)}>
      <sphereGeometry args={[10]} />
      <meshStandardMaterial
        color={day ? "white" : "yellow"}
        emissive={day ? "yellow" : "black"}
      />
    </mesh>
  )
}

const Scene = ({ day, updateTheme }) => {
  const ref = useRef<THREE.DirectionalLight>(null!)

  useEffect(() => {
    ref.current.shadow.camera.left = 30
    ref.current.shadow.camera.right = -30
    ref.current.shadow.camera.top = 10
    ref.current.shadow.camera.bottom = -10
  }, [])

  return (
    <>
      <ambientLight intensity={day ? 0.3 : 0.1} />
      <directionalLight
        ref={ref}
        castShadow
        intensity={day ? 0.8 : 0.1}
        position={[-120, 20, -80]}
      />
      <Sun day={day} updateTheme={updateTheme} />
      <OrbitControls />
      <Physics>
        <Debug color={"red"}>
          <Ground position={[-30, 0, 24]} />
          <Lantern
            position={[2, -0.5, 8]}
            day={day}
            scale={0.6}
            updateTheme={updateTheme}
          />
          <Sign />
          <Rail />
        </Debug>
      </Physics>
    </>
  )
}

export default function working() {
  const { theme, updateTheme } = useContext(ThemeContext)
  let day = theme === Themes.LIGHT

  return (
    <Layout contentWidth="100vw">
      <div className={s.container}>
        <Canvas shadows camera={{ position: [10, 4, -3] }}>
          {/* <Canvas shadows camera={{ position: [0, 26, 0] }}> */}
          <color attach="background" args={[day ? "lightblue" : "black"]} />
          <Scene day={day} updateTheme={updateTheme} />
        </Canvas>
      </div>
    </Layout>
  )
}
